import HeaderBar from 'components/layouts/header/HeaderBar';
import Sidebar from 'components/layouts/sidebar/Sidebar';
import React, { useState } from 'react';
import { Redirect } from 'react-router';

import PrivacyPolicy from 'modules/auth/pages/PrivacyPolicy/PrivacyPolicy';
import localStorageService from '../helpers/localStorageService';

import './PrivateSection.less';

function PrivateSection({
  children,
}: // eslint-disable-next-line @typescript-eslint/ban-types

React.PropsWithChildren<{}>): JSX.Element | null {
  const adminStatus = localStorageService.get('adminStatus');
  const forgotPassword = sessionStorage.getItem('forgotPassword');
  const resetPassword = localStorage.getItem('resetPassword');
  const currentURL = window.location.pathname;

  const [sidebarToggle, setSidebarToggle] = useState(false);
  const showSidebar = (): void => setSidebarToggle(!sidebarToggle);

  if (currentURL.includes('/privacy-policy')) {
    return <PrivacyPolicy />;
  }

  if (adminStatus !== 'active' && forgotPassword !== 'true' && resetPassword !== 'true') {
    return <Redirect to="/auth/login" />;
  }

  if (adminStatus !== 'active' && forgotPassword === 'true') {
    return <Redirect to="/auth/forgotpassword" />;
  }

  if (adminStatus !== 'active' && resetPassword === 'true') {
    return <Redirect to="/auth/resetPassword" />;
  }

  if (currentURL === '/auth/login' && adminStatus === 'active') {
    window.location.href = '/dashboard';
  }

  return (
    <>
      <HeaderBar showSidebar={showSidebar} />
      <Sidebar sidebarToggle={sidebarToggle} />
      <div className={`content_wrapper ${sidebarToggle === true ? 'sidebar-expand-content' : 'sidebar-close-content'}`}>
        <div className="childrenWraaper">{children}</div>
      </div>
    </>
  );
}

export default PrivateSection;
