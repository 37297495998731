import { LIVE_REFRESH_INTERVAL, USE_QUERY_OPTIONS } from 'constants/config';
import { useMutation, useQuery } from 'react-query';
import { GetUnidentifiedRequest } from 'services/unidentifiedEvents';
import hosApi, {
  GetHosHistoryRequest,
  GetHosLiveDataRequest,
  GetHosProfileDataRequest,
  GetHosDriverLogsRequest,
  GetUnitsRequest,
  GetHosDriverGraphDataRequest,
  GetLogsSelectRequest,
  GetHosDriverCSVRequest,
  GetHosEditedHistoryRequest,
  GetHosTrackingRequest,
  GetEditedHistoryRequest,
  GetOriginalLogsRequest,
  GetUnitsLogRequest,
} from 'services/unit';

import {
  GET_HOS,
  GET_HOS_CLOCK,
  GET_HOS_DRIVER_CSV,
  GET_HOS_DRIVER_EDITED_HISTORY,
  GET_HOS_DRIVER_GRAPH_DATA,
  GET_HOS_DRIVER_LIVE_HISTORY,
  GET_HOS_DRIVER_LOGS,
  GET_HOS_DRIVER_TRACKING_HISTORY,
  GET_HOS_LIVE,
  GET_HOS_PROFILE,
  GET_LOG_FORM,
  GET_SINGLE_UNIT_BY_DRIVERID,
  GET_UNIDENTIFIED_ACUMOLATED_HOURS,
  GET_UNIDENTIFIED_ASSIGN_EVENTS,
  GET_UNIDENTIFIED_EVENTS,
  GET_UNITS,
  GET_HISTORY,
  GET_HISTORY_DETAILS,
  GET_LOCATION_LAT_LONG,
  GET_ADDRESS_COORDINATES,
  GET_HOS_DRIVER_TRIP_TRACKING_HISTORY,
} from './keys';

export const useGetUnits = (params: GetUnitsRequest) =>
  useQuery([GET_UNITS, params], () => hosApi.getUnits(params), {
    ...USE_QUERY_OPTIONS,
    // refetchInterval: LIVE_REFRESH_INTERVAL,
  });

export const useGetLogUnits = (params: GetUnitsLogRequest) =>
  useQuery([GET_UNITS, params], () => hosApi.getUnitsLogs(params), {
    ...USE_QUERY_OPTIONS,
    // refetchInterval: LIVE_REFRESH_INTERVAL,
  });
export const useGetSingleUnitByDriverId = (driverId: string) =>
  useQuery([GET_SINGLE_UNIT_BY_DRIVERID, driverId], () => hosApi.getSingleUnitByDriverId(driverId), {
    ...USE_QUERY_OPTIONS,
    select: ({ data }) => data,
    refetchOnMount: 'always',
  });

export const useGetDriverLogs = (params: GetHosDriverLogsRequest) =>
  useQuery([GET_HOS_DRIVER_LOGS, params], () => hosApi.getDriverLogs(params), { ...USE_QUERY_OPTIONS });

// LogListing

export const useGetLogsListing = (params: GetLogsSelectRequest) =>
  useQuery([GET_HOS_DRIVER_LOGS, params], () => hosApi.getLogsListing(params), { ...USE_QUERY_OPTIONS });

export const useGetDriverGraphData = (params: GetHosDriverGraphDataRequest) =>
  useQuery([GET_HOS_DRIVER_GRAPH_DATA, params], () => hosApi.getDriverGraphData(params), { ...USE_QUERY_OPTIONS });

export const useGetDriverCSV = (params: GetHosDriverCSVRequest) =>
  useQuery([GET_HOS_DRIVER_CSV, params], () => hosApi.getDriverCSV(params), {
    refetchOnMount: 'always',
    ...USE_QUERY_OPTIONS,
  });

export const useGetLiveHistory = (params: GetHosDriverCSVRequest) =>
  useQuery([GET_HOS_DRIVER_LIVE_HISTORY, params], () => hosApi.getDriverLiveHistory(params), {
    ...USE_QUERY_OPTIONS,
    refetchOnMount: 'always',
  });

export const useGetTrackingHistory = (params: GetHosTrackingRequest) =>
  useQuery(
    [GET_HOS_DRIVER_TRACKING_HISTORY, params],
    () => hosApi.getDriverTrackingHistory(params),
    params?.isToday
      ? {
          refetchOnMount: 'always',
          refetchInterval: 60000,
        }
      : {
          refetchOnMount: 'always',
        },
  );

export const useGetTripHistory = (params: GetHosTrackingRequest) =>
  useQuery(
    [GET_HOS_DRIVER_TRIP_TRACKING_HISTORY, params],
    () => hosApi.getTripTrackingHistory(params),
    params?.isToday
      ? {
          refetchOnMount: 'always',
          refetchInterval: 60000,
        }
      : {
          refetchOnMount: 'always',
        },
  );

export const useGetOriginalLogs = (params: GetOriginalLogsRequest) =>
  useQuery([GET_HISTORY_DETAILS], () => hosApi.getOriginalLogs(params), {
    refetchOnMount: 'always',
  });

export const useGetHistory = (params: GetEditedHistoryRequest) =>
  useQuery([GET_HISTORY], () => hosApi.getHistory(params), {
    refetchOnMount: 'always',
  });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useGetHistoryDetails = (params: string, timezone: string) =>
  useQuery([GET_HISTORY_DETAILS], () => hosApi.getHistoryDetails(params, timezone), {
    refetchOnMount: 'always',
  });

export const useGetDriverEditedHistoryCSV = (params: GetHosEditedHistoryRequest) =>
  useQuery([GET_HOS_DRIVER_EDITED_HISTORY, params], () => hosApi.getDriverEditedHistory(params), {
    ...USE_QUERY_OPTIONS,
  });

// export const useSaveEditLog = () => useMutation(hosApi.editDriverLog, { mutationKey: 'saveEditLog' });

export const useSaveEditLog = () => useMutation(hosApi.editDriverLog, { mutationKey: 'saveEditLog' });

export const useCancelEditLog = () => useMutation(hosApi.cancelDriverLog, { mutationKey: 'cancelEditLog' });

export const useNotificationSendEditLog = () =>
  useMutation(hosApi.notificationSendEditLog, { mutationKey: 'notificationSendEditLog' });

export const useGetHosHistory = (params: GetHosHistoryRequest) =>
  useQuery([GET_HOS, params], () => hosApi.getHistroy(params), { ...USE_QUERY_OPTIONS });

export const useGetHosLiveData = (params: GetHosLiveDataRequest, refetchIntervalVal?: number) =>
  useQuery([GET_HOS_LIVE, params], () => hosApi.getLiveData(params), {
    refetchOnMount: 'always',
    refetchInterval: refetchIntervalVal || false,
  });

export const useGetHosLiveDataCurrentLocation = (params: GetHosLiveDataRequest) =>
  useQuery([GET_HOS_LIVE, params], () => hosApi.getLiveData(params), { refetchOnMount: 'always' });

export const useGetHosProfileData = (params: GetHosProfileDataRequest) =>
  useQuery([GET_HOS_PROFILE, params], () => hosApi.getProfileData(params));

export const useGetHOSClock = (params: GetHosProfileDataRequest) =>
  useQuery([GET_HOS_CLOCK, params], () => hosApi.getHOSCycle(params));

// Log Form

export const useUpdateLogForm = () => useMutation(hosApi.updateLogForm, { mutationKey: 'updateLogForm' });

export const useGetLogForm = (params: GetHosHistoryRequest) =>
  useQuery([GET_LOG_FORM, params], () => hosApi.getLogForm(params), { ...USE_QUERY_OPTIONS });

// Unidentified Events

export const useGetUnidentifiedEvents = (params: GetUnidentifiedRequest) =>
  useQuery([GET_UNIDENTIFIED_EVENTS, params], () => hosApi.getUnidentifiedEvents(params), { ...USE_QUERY_OPTIONS });

export const useGetUnidentifiedAccumulatedhours = () =>
  useQuery([GET_UNIDENTIFIED_ACUMOLATED_HOURS], () => hosApi.getUnidentifiedAccumulatedhours(), {
    ...USE_QUERY_OPTIONS,
  });
export const useGetUnidentifiedAssignEvents = (unidentifiedEventId: string) =>
  useQuery(
    [GET_UNIDENTIFIED_ASSIGN_EVENTS, unidentifiedEventId],
    () => hosApi.getUnidentifiedAssignEvents(unidentifiedEventId),
    {
      ...USE_QUERY_OPTIONS,
      refetchOnMount: 'always',
    },
  );
export const useSaveInsertInfoLog = () => useMutation(hosApi.saveInsertInfoLog, { mutationKey: 'saveInsertInfoLog' });

export const useSaveUnidentifiedAssign = () =>
  useMutation(hosApi.saveUnidentifiedAssign, { mutationKey: 'saveUnidentifiedAssign' });

export const useCancelPendingUnidentified = () =>
  useMutation(hosApi.cancelPendingUnidentified, { mutationKey: 'cancelPendingUnidentified' });

export const useDeleteLogs = () => useMutation(hosApi.deleteLogs, { mutationKey: 'deleteLogs' });

export const useNormalizeLogs = () => useMutation(hosApi.normalizeLogs, { mutationKey: 'normalizeLogs' });

export const useTransferLogs = () => useMutation(hosApi.transferLogs, { mutationKey: 'transferLogs' });

export const useGetLatLong = () => useMutation(hosApi.getLocationLatLong, { mutationKey: GET_LOCATION_LAT_LONG });

export const useGetCoordinates = () =>
  useMutation(hosApi.getAddressCoordinates, { mutationKey: GET_ADDRESS_COORDINATES });
