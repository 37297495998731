import { DATE_ONLY } from 'constants/dates';
import { DEFAULT_MAP_ZOOM, GOOGLE_API_KEY, HISTORY_MAP_ZOOM } from 'constants/config';
import { Col, Row, Spin } from 'antd';
import { useCallback, useEffect, useRef, useState } from 'react';
import { GoogleMap, useJsApiLoader, Polyline, Marker, InfoWindow } from '@react-google-maps/api';
import moment from 'moment';

import { useGetTripHistory } from 'hooks/unitHooks';
import { TrackingResponseType } from 'services/unit';
import truckIcon from 'resources/icons/truck-moving.svg';
import moveIcon from 'resources/icons/navigate-sharp-round.svg';
import CustomTrackingInfo from 'modules/units/components/Partials/HistroyTab/CustomTrackingInfo';
import { isEmpty } from 'lodash';
// import CustomInfoWindow from './CustomInfoWindow';

interface Position {
  lat: number;
  lng: number;
}

const center: Position = {
  lat: 36.7783,
  lng: -119.4179,
};
export interface Filter {
  driverId: string;
  date: string;
}
const Tracking = ({ initialFilter, currentDate }: { initialFilter: Filter; currentDate: string }) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_API_KEY,
  });

  const [path, setPath] = useState<TrackingResponseType[]>([]);
  const [stop, setStop] = useState<TrackingResponseType[]>([]);
  const [polilinepathMapArray, setPolilinepathMapArray] = useState<
    | {
        lat: number;
        lng: number;
      }[]
    | undefined
  >([]);
  const isToday = moment(currentDate, 'MMDDYY').format(DATE_ONLY) === initialFilter?.date;
  const { data: trackingData, isLoading: isLoadingTracking } = useGetTripHistory({ ...initialFilter, isToday });
  const mapRef = useRef<google.maps.Map>();
  const [selectedMarker, setSelectedMarker] = useState<TrackingResponseType | null>(null);
  const [indexTrack, setIndexTrack] = useState<number | null>(null);
  const [rotatedIconUrlLive, setRotatedIconUrlLive] = useState<string>();
  const handleMarkerClick = (marker: TrackingResponseType, index: number) => {
    setSelectedMarker(marker);
    setIndexTrack(index);
  };

  const handleInfoWindowClose = () => {
    setSelectedMarker(null);
  };

  useEffect(() => {
    if (!isEmpty(trackingData)) {
      const arrayAddress = trackingData?.data?.map((item: TrackingResponseType, index: number, arrayData) => ({
        ...item,
        address: trackingData?.data[index + 1]?.address || arrayData[index + 2]?.address || arrayData[index]?.address,
      }));
      setPath(() =>
        arrayAddress
          ?.filter(
            (item: TrackingResponseType) =>
              item?.status === '3' || (item?.eventType === '3' && (item?.status === '1' || item?.status === '2')),
          )
          .map((item: TrackingResponseType) => ({ ...item })),
      );
      let sum: number = 0;
      let keyDuration: number = -1;
      for (let i = 0; i < trackingData?.data?.length; i += 1) {
        const duration = trackingData?.data[i]?.duration;
        if (duration !== undefined) {
          sum += duration;
          if (keyDuration === -1) {
            keyDuration = i;
          }
        }
        if (
          trackingData?.data![i]?.status === '3' ||
          (trackingData?.data![i]?.eventType === '3' &&
            (trackingData?.data![i]?.status === '1' || trackingData?.data![i]?.status === '2'))
        ) {
          if (
            sum > 0 ||
            (trackingData?.data![i - 1]?.status !== '3' &&
              trackingData?.data![i - 1]?.eventType !== '3' &&
              (trackingData?.data![i - 1]?.status !== '1' || trackingData?.data![i - 1]?.status !== '2'))
          ) {
            stop.push({
              ...trackingData?.data![i],
              calculatedDuration: sum,
              address:
                trackingData?.data![i - 1]?.address ||
                trackingData?.data![i]?.address ||
                trackingData?.data![i + 1]?.address,
              startTime: trackingData?.data![keyDuration]?.time,
            });
            sum = 0;
          }
        }
        if (
          (trackingData?.data![i - 1]?.status === '3' ||
            (trackingData?.data![i - 1]?.eventType === '3' &&
              (trackingData?.data![i - 1]?.status === '1' || trackingData?.data![i - 1]?.status === '2'))) &&
          trackingData?.data![i]?.status !== '3' &&
          trackingData?.data![i]?.eventType !== '3' &&
          (trackingData?.data![i]?.status !== '1' || trackingData?.data![i]?.status !== '2')
        ) {
          keyDuration = i;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackingData, stop]);

  useEffect(() => {
    if (!isEmpty(trackingData) && isLoaded) {
      const marker = {
        lat: path![path?.length - 1]?.latitude,
        lng: path![path?.length - 1]?.longitude,
      };

      let previousIndex = 0;
      if (path?.length - 1 !== 0) {
        previousIndex = path?.length - 2;
      }

      const prev = {
        lat: path[previousIndex]?.latitude,
        lng: path[previousIndex]?.longitude,
      };
      // const prevLatLng = new google.maps.LatLng(
      //   Number(prev?.lat),
      //   Number(prev?.lng)
      // );
      // const markerLatLng = new google.maps.LatLng(
      //   Number(marker?.lat),
      //   Number(marker?.lng)
      // );

      const rotationAngle =
        Math.atan2(Number(marker?.lat) - Number(prev?.lat), Number(marker?.lng) - Number(prev?.lng)) * (180 / Math.PI);
      // const rotationAngle = google?.maps?.geometry?.spherical?.computeHeading(
      //   markerLatLng,
      //   prevLatLng,
      // );
      const img = new Image();
      const url = !isToday ? moveIcon : truckIcon;
      img.src = url;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;
        ctx?.translate(canvas.width / 2, canvas.height / 2);
        ctx?.rotate(rotationAngle!);
        ctx?.drawImage(img, -img.width / 2, -img.height / 2);
        setRotatedIconUrlLive(canvas.toDataURL());
      };
      setPolilinepathMapArray(
        trackingData?.data
          .filter(item => {
            const { status, eventType, latitude, longitude } = item;
            const isValidStatus = status === '3' || (eventType === '3' && (status === '1' || status === '2'));
            const isValidLocation = latitude !== 'X' && longitude !== 'X' && latitude !== '0' && longitude !== '0';
            return isValidStatus && isValidLocation;
          })
          .map(item => ({
            lat: Number(item.latitude),
            lng: Number(item.longitude),
          })),
      );
    }
  }, [path, isLoaded, trackingData]);

  useEffect(() => {
    setStop([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackingData]);

  useEffect(() => {
    setStop([]);
    setPath([]);
    setPolilinepathMapArray([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFilter]);

  const onLoad = useCallback(
    map => {
      mapRef.current = map;
      if (!isEmpty(path)) {
        const bounds = new google.maps.LatLngBounds();
        path.forEach(item => {
          bounds.extend({
            lat: Number(item?.latitude),
            lng: Number(item?.longitude),
          });
        });
        mapRef.current?.fitBounds(bounds);
      }
    },
    [path],
  );

  useEffect(() => {
    if (!isEmpty(polilinepathMapArray) && isLoaded) {
      const bounds = new google.maps.LatLngBounds();
      polilinepathMapArray!.forEach(item => {
        bounds.extend({
          lat: Number(item?.lat),
          lng: Number(item?.lng),
        });
      });
      mapRef?.current?.fitBounds(bounds);
    }
  }, [isLoaded]);

  return (
    <Spin spinning={isLoadingTracking}>
      <Row>
        <Col span={24}>
          <div className="generic-card-small LogEvents current-location-section">
            <Row style={{ paddingBottom: '19px' }}>
              <Col span={5}>
                <div className="pg-heading">Tracking</div>
              </Col>
            </Row>
            <Row gutter={80}>
              <Col span={24}>
                <div className="hr-line" />
                <div className="map-content">
                  <Row style={{ paddingTop: '12px' }}>
                    <Col span={24}>
                      <div style={{ height: '400px', width: '100%' }}>
                        {isLoaded
                          ? [
                              path?.length !== 0 && !isLoadingTracking ? (
                                <GoogleMap
                                  center={center}
                                  zoom={path?.length > 0 ? HISTORY_MAP_ZOOM : DEFAULT_MAP_ZOOM}
                                  mapContainerStyle={{ width: '100%', height: '100%' }}
                                  options={{
                                    minZoom: 3,
                                    zoomControl: true,
                                    zoomControlOptions: {
                                      // style: google.maps.ZoomControlStyle.SMALL,
                                      position: google.maps.ControlPosition.RIGHT_TOP,
                                    },
                                    streetViewControl: true,
                                    streetViewControlOptions: {
                                      position: google.maps.ControlPosition.RIGHT_TOP,
                                    },
                                    mapTypeControl: true,
                                    fullscreenControl: true,
                                    fullscreenControlOptions: {
                                      position: google.maps.ControlPosition.RIGHT_TOP,
                                    },
                                  }}
                                  onLoad={onLoad}>
                                  <>
                                    <Polyline
                                      path={polilinepathMapArray}
                                      options={{ strokeColor: '#52c41a', strokeWeight: 5, strokeOpacity: 0.8 }}
                                    />
                                    {stop?.map((item: TrackingResponseType, index: number) => {
                                      let markerIcon = '';
                                      if (index === 0) {
                                        markerIcon = '/assets/icons/location.svg';
                                      } else {
                                        markerIcon = '/assets/icons/stop-sharp-round.svg';
                                      }
                                      return (
                                        <>
                                          <Marker
                                            key={index.toString() + 1}
                                            position={{
                                              lat: Number(item?.latitude),
                                              lng: Number(item?.longitude),
                                            }}
                                            icon={{
                                              url: markerIcon,
                                              scaledSize: new google.maps.Size(35, 35),
                                              origin: new google.maps.Point(0, 0),
                                              anchor: new google.maps.Point(17, 17),
                                            }}
                                            onClick={() => {
                                              handleMarkerClick(item, index);
                                            }}
                                          />
                                        </>
                                      );
                                    })}
                                    {polilinepathMapArray && (
                                      <Marker
                                        key={polilinepathMapArray[polilinepathMapArray?.length - 1]?.lat}
                                        position={{
                                          lat: polilinepathMapArray[polilinepathMapArray?.length - 1]?.lat,
                                          lng: polilinepathMapArray[polilinepathMapArray?.length - 1]?.lng,
                                        }}
                                        icon={{
                                          url: rotatedIconUrlLive || '',
                                          scaledSize: new google.maps.Size(35, 35),
                                          origin: new google.maps.Point(0, 0),
                                          anchor: new google.maps.Point(17, 17),
                                          rotation: 180,
                                        }}
                                        onClick={() => {
                                          handleMarkerClick(path[path?.length - 1], path?.length - 1);
                                        }}
                                      />
                                    )}

                                    {selectedMarker && (
                                      <InfoWindow
                                        position={{
                                          lat: Number(selectedMarker?.latitude),
                                          lng: Number(selectedMarker?.longitude),
                                        }}
                                        onCloseClick={handleInfoWindowClose}>
                                        <CustomTrackingInfo
                                          info={selectedMarker}
                                          indexTrack={indexTrack}
                                          lengthArray={
                                            indexTrack === path?.length - 1 ? path?.length : stop?.length + 1
                                          }
                                          convertedDate={currentDate}
                                        />
                                      </InfoWindow>
                                    )}
                                  </>
                                </GoogleMap>
                              ) : (
                                <Col
                                  span={24}
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    paddingTop: '150px',
                                  }}>
                                  <div>
                                    <h2>No Tracking</h2>
                                  </div>
                                </Col>
                              ),
                            ]
                          : 'Loading...'}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Spin>
  );
};

export default Tracking;
