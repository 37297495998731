import './PrivacyPolicy.less'; // Import the CSS file
import Logo from 'resources/images/Group_34.png';

const PrivacyPolicy = () => (
  <div className="privacy-container">
    <div className="privacy-content">
      <div className="privacy-header">
        <img className="logo-img" src={Logo} alt="LogELD logo" loading="lazy" />
        <p className="privacy-title">Privacy Policy</p>
      </div>
      <h2 className="privacy-section-title">Introduction</h2>
      <p>
        Welcome to LogELD. At LogELD, we prioritize your privacy and are dedicated to ensuring that your personal
        information is protected. This Privacy Policy explains how we collect, use, disclose, and safeguard your
        information when you use our services. By accessing or using LogELD, you agree to the collection and use of your
        information in accordance with this policy.
      </p>
      <p>
        LogELD provides comprehensive electronic logging solutions to help fleets and drivers comply with federal
        regulations, improve operational efficiency, and enhance safety. Our services include the collection and
        processing of vehicle data, driver information, and other relevant data to ensure compliance with Hours of
        Service (HOS) regulations.
      </p>
      <h2 className="privacy-section-title">Information We Collect</h2>
      <h3 className="privacy-subsection-title">Personal Information</h3>
      <p>We collect personal information that you provide directly to us, including but not limited to:</p>
      <ul>
        <li>Contact Information: Name, email address, phone number, and mailing address.</li>
        <li>Account Information: Username, password, and other authentication details.</li>
        <li>Billing Information: Credit card details and billing address for payment processing.</li>
      </ul>
      <h3 className="privacy-subsection-title">Device and Usage Information</h3>
      <p>We collect information about the devices you use to access our services, including:</p>
      <ul>
        <li>Device Details: IP address, device type, operating system, and browser type.</li>
        <li>Usage Data: Log files, access times, pages viewed, and other usage information.</li>
        <li>
          Location Data: GPS data and other location-based information for vehicle tracking and compliance purposes.
        </li>
      </ul>
      <h3 className="privacy-subsection-title">Cookies and Tracking Technologies</h3>
      <p>
        We use cookies, web beacons, and similar tracking technologies to collect information about your interactions
        with our website and services. This helps us understand user behavior, customize your experience, and improve
        our services.
      </p>
      <h2 className="privacy-section-title">How We Use Your Information</h2>
      <h3 className="privacy-subsection-title">To Provide and Improve Services</h3>
      <p>We use the information we collect to:</p>
      <ul>
        <li>Deliver and maintain our services.</li>
        <li>Troubleshoot and fix issues.</li>
        <li>Conduct data analysis and testing.</li>
        <li>Develop new features and enhancements.</li>
      </ul>
      <h3 className="privacy-subsection-title">Communication</h3>
      <p>We use your personal information to:</p>
      <ul>
        <li>Communicate with you about your account.</li>
        <li>Respond to your inquiries and support requests.</li>
        <li>Send you updates, notifications, and promotional materials.</li>
      </ul>
      <h3 className="privacy-subsection-title">Compliance and Security</h3>
      <p>We use your information to:</p>
      <ul>
        <li>Ensure compliance with legal obligations and regulations.</li>
        <li>Enforce our terms and conditions.</li>
        <li>Protect the security and integrity of our services.</li>
      </ul>
      <h2 className="privacy-section-title">Sharing Your Information</h2>
      <h3 className="privacy-subsection-title">With Third Parties</h3>
      <p>
        We may share your information with third-party service providers who assist us in delivering our services, such
        as:
      </p>
      <ul>
        <li>Payment Processors: For processing payments securely.</li>
        <li>Cloud Storage Providers: For data storage and management.</li>
        <li>Customer Support Platforms: For providing customer support and communication.</li>
      </ul>
      <h3 className="privacy-subsection-title">Legal Requirements</h3>
      <p>
        We may disclose your information if required to do so by law or in response to valid requests by public
        authorities, such as:
      </p>
      <ul>
        <li>Law enforcement agencies.</li>
        <li>Regulatory bodies.</li>
        <li>Government entities.</li>
      </ul>
      <h3 className="privacy-subsection-title">Business Transfers</h3>
      <p>
        In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be
        transferred to the new owner. We will notify you before your personal information is transferred and becomes
        subject to a different privacy policy.
      </p>
      <h2 className="privacy-section-title">Your Rights and Choices</h2>
      <h3 className="privacy-subsection-title">Access and Correction</h3>
      <p>You have the right to access and correct your personal information. You can do this by:</p>
      <ul>
        <li>Logging into your account and updating your information.</li>
        <li>Contacting us directly for assistance.</li>
      </ul>
      <h3 className="privacy-subsection-title">Data Portability</h3>
      <p>
        You have the right to request a copy of your personal data in a structured, commonly used, and machine-readable
        format. This allows you to transfer your data to another service provider if you choose to do so.
      </p>
      <h3 className="privacy-subsection-title">Deletion</h3>
      <p>
        You have the right to request the deletion of your personal information, subject to certain legal and
        operational limitations. We will comply with your request where applicable, but please note that we may retain
        certain information as required by law or for legitimate business purposes.
      </p>
      <h2 className="privacy-section-title">Security</h2>
      <p>
        We implement appropriate technical and organizational measures to protect your personal information against
        unauthorized access, alteration, disclosure, or destruction. These measures include:
      </p>
      <ul>
        <li>Encryption of data in transit and at rest.</li>
        <li>Regular security assessments and audits.</li>
        <li>Access controls and authentication mechanisms.</li>
      </ul>
      <h2 className="privacy-section-title">Changes To This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements.
        We will notify you of any changes by:
      </p>
      <ul>
        <li>Posting the new Privacy Policy on our website.</li>
        <li>Updating the effective date at the top of this policy.</li>
      </ul>
      <h2 className="privacy-section-title">Contact Us</h2>
      <p>If you have any questions about this Privacy Policy or our data practices, please contact us at:</p>
      <p>LogELD Support</p>
      Email: <span className="privacy-email">support@logeld.com</span>
    </div>
  </div>
);

export default PrivacyPolicy;
