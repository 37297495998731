import { DAY_MONTH_YEAR } from 'constants/dates';
import { DEFAULT_MAP_ZOOM, GOOGLE_API_KEY, LIVE_MAP_ZOOM, STATUSE_TYPE_INTO_ENEVT_CODE } from 'constants/config';
import { useState } from 'react';
import { Col, Row, Tag } from 'antd';

import moment from 'moment';
import { BsChevronDoubleDown, BsChevronDoubleUp } from 'react-icons/bs';
import { MdOutlineRefresh } from 'react-icons/md';
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from '@react-google-maps/api';

import { DriverResponseType } from 'services/drivers';
import { TimeZone } from 'types/common';

import { useGetHosLiveDataCurrentLocation } from 'hooks/unitHooks';

import './CurrentLocation.less';

const CurrentLocation = ({
  driverId,
  driverDetail,
  showCurrentLocation,
  setShowCurrentLocation,
}: {
  driverId: string;
  driverDetail: DriverResponseType | undefined;
  showCurrentLocation: boolean;
  setShowCurrentLocation: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { data: liveData } = useGetHosLiveDataCurrentLocation({ driverId });

  const [truckInfoWindowOpen, setTruckInfoWindowOpen] = useState(false);
  // const [switchMa,]
  const { tzCode } = (driverDetail?.homeTerminalTimeZone as TimeZone) ?? { tzCode: String };

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_API_KEY,
  });

  const latitude = Number(liveData?.data?.lastActivity?.latitude) || 37.2755586;
  const longitude = Number(liveData?.data?.lastActivity?.longitude) || -104.6573965;

  const defaultProps = {
    center: {
      lat: latitude,
      lng: longitude,
    },
  };
  let statusColor = '';
  if (liveData?.data?.lastActivity?.currentEventType === '1') {
    if (liveData?.data?.lastActivity?.currentEventCode === STATUSE_TYPE_INTO_ENEVT_CODE.OFF_DUTY) {
      statusColor = '#586973';
    } else if (liveData?.data?.lastActivity?.currentEventCode === STATUSE_TYPE_INTO_ENEVT_CODE.ON_DUTY) {
      statusColor = '#2b5f8c';
    } else if (liveData?.data?.lastActivity?.currentEventCode === STATUSE_TYPE_INTO_ENEVT_CODE.ON_DRIVING) {
      statusColor = '#52C41A';
    } else if (liveData?.data?.lastActivity?.currentEventCode === STATUSE_TYPE_INTO_ENEVT_CODE.SLEEPER_BERTH) {
      statusColor = '#FF9933';
    }
  } else if (liveData?.data?.lastActivity?.currentEventType === '3') {
    if (liveData?.data?.lastActivity?.currentEventCode === '1') {
      statusColor = '#858e9ef3';
    } else if (liveData?.data?.lastActivity?.currentEventCode === '2') {
      statusColor = '#1ca1bff3';
    }
  } else {
    statusColor = '#586973';
  }
  const handleTruckMarkerClick = () => {
    setTruckInfoWindowOpen(true);
  };

  const handleTruckInfoWindowClose = () => {
    setTruckInfoWindowOpen(false);
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <div className="generic-card-small LogEvents current-location-section">
            <Row style={{ paddingBottom: '19px' }}>
              <Col span={5}>
                <div className="pg-heading">Current Location</div>
              </Col>
              <Col offset={18}>
                {!showCurrentLocation ? (
                  <BsChevronDoubleDown
                    style={{ cursor: 'pointer' }}
                    onClick={() => setShowCurrentLocation(!showCurrentLocation)}
                  />
                ) : (
                  <BsChevronDoubleUp
                    style={{ cursor: 'pointer' }}
                    onClick={() => setShowCurrentLocation(!showCurrentLocation)}
                  />
                )}
              </Col>
            </Row>
            <Row gutter={80}>
              <Col span={24}>
                <div className="hr-line" />
                <ul className="sub-childs">
                  <Row style={{ paddingTop: '12px' }}>
                    <Col span={24}>
                      {showCurrentLocation ? (
                        <div style={{ height: '400px', width: '100%' }}>
                          {isLoaded ? (
                            <>
                              <GoogleMap
                                center={defaultProps.center}
                                zoom={liveData?.data?.lastActivity?.latitude ? LIVE_MAP_ZOOM : DEFAULT_MAP_ZOOM}
                                mapContainerStyle={{ width: '100%', height: '100%' }}
                                options={{
                                  minZoom: 3,
                                  zoomControl: true,
                                  streetViewControl: true,
                                  mapTypeControl: true,
                                  fullscreenControl: true,
                                }}>
                                {liveData?.data?.lastActivity?.latitude && (
                                  <Marker
                                    position={defaultProps.center}
                                    onClick={handleTruckMarkerClick}
                                    icon={{
                                      url: '/assets/icons/Group 2332.svg',
                                      scaledSize: new window.google.maps.Size(35, 35),
                                      origin: new window.google.maps.Point(0, 0),
                                      anchor: new window.google.maps.Point(17, 17),
                                    }}
                                  />
                                )}
                                {truckInfoWindowOpen && (
                                  <InfoWindow position={defaultProps.center} onCloseClick={handleTruckInfoWindowClose}>
                                    <div>
                                      <>
                                        <div className="custom_info_design_current-location">
                                          <ul className="info_list">
                                            <li>
                                              <Row gutter={20}>
                                                <Col span={8}>
                                                  <p className="p-body">Status:</p>
                                                </Col>
                                                <Col span={16}>
                                                  <p>
                                                    <>
                                                      {' '}
                                                      <Tag
                                                        color={statusColor}
                                                        className={`tag-custom-style dutyStatus ${
                                                          liveData?.data?.lastActivity?.currentEventType === '1'
                                                            ? [
                                                                liveData?.data?.lastActivity?.currentEventCode ===
                                                                STATUSE_TYPE_INTO_ENEVT_CODE.OFF_DUTY
                                                                  ? 'offduty'
                                                                  : [
                                                                      liveData?.data?.lastActivity?.currentEventCode ===
                                                                      STATUSE_TYPE_INTO_ENEVT_CODE.ON_DUTY
                                                                        ? 'onduty'
                                                                        : [
                                                                            liveData?.data?.lastActivity
                                                                              ?.currentEventCode ===
                                                                            STATUSE_TYPE_INTO_ENEVT_CODE.SLEEPER_BERTH
                                                                              ? 'onSleeperBirth'
                                                                              : [
                                                                                  liveData?.data?.lastActivity
                                                                                    ?.currentEventCode ===
                                                                                  STATUSE_TYPE_INTO_ENEVT_CODE.ON_DRIVING
                                                                                    ? 'ondriving'
                                                                                    : 'offduty',
                                                                                ],
                                                                          ],
                                                                    ],
                                                              ]
                                                            : [
                                                                liveData?.data?.lastActivity?.currentEventType === '3'
                                                                  ? [
                                                                      liveData?.data?.lastActivity?.currentEventCode ===
                                                                      '1'
                                                                        ? 'offdutypc'
                                                                        : [
                                                                            liveData?.data?.lastActivity
                                                                              ?.currentEventCode === '2'
                                                                              ? 'ondutyym'
                                                                              : 'offduty',
                                                                          ],
                                                                    ]
                                                                  : 'offduty',
                                                              ]
                                                        }`}>
                                                        {liveData?.data?.lastActivity?.currentEventType === '1'
                                                          ? [
                                                              liveData?.data?.lastActivity?.currentEventCode ===
                                                              STATUSE_TYPE_INTO_ENEVT_CODE.OFF_DUTY
                                                                ? 'OFF DUTY'
                                                                : [
                                                                    liveData?.data?.lastActivity?.currentEventCode ===
                                                                    STATUSE_TYPE_INTO_ENEVT_CODE.ON_DUTY
                                                                      ? 'ON DUTY'
                                                                      : [
                                                                          liveData?.data?.lastActivity
                                                                            ?.currentEventCode ===
                                                                          STATUSE_TYPE_INTO_ENEVT_CODE.SLEEPER_BERTH
                                                                            ? 'SLEEPER BERTH'
                                                                            : [
                                                                                liveData?.data?.lastActivity
                                                                                  ?.currentEventCode ===
                                                                                STATUSE_TYPE_INTO_ENEVT_CODE.ON_DRIVING
                                                                                  ? 'Driving'
                                                                                  : 'OFF DUTY',
                                                                              ],
                                                                        ],
                                                                  ],
                                                            ]
                                                          : [
                                                              liveData?.data?.lastActivity?.currentEventType === '3'
                                                                ? [
                                                                    liveData?.data?.lastActivity?.currentEventCode ===
                                                                    '1'
                                                                      ? 'OFF DUTY (PC)'
                                                                      : [
                                                                          liveData?.data?.lastActivity
                                                                            ?.currentEventCode === '2'
                                                                            ? 'ON DUTY (YM)'
                                                                            : 'OFF DUTY',
                                                                        ],
                                                                  ]
                                                                : 'OFF DUTY',
                                                            ]}
                                                      </Tag>
                                                    </>
                                                  </p>
                                                </Col>
                                              </Row>
                                            </li>
                                            <li>
                                              <Row className="address_row-current_location" gutter={24}>
                                                <Col span={8}>
                                                  <p className="p-body">Address:</p>
                                                </Col>
                                                <Col span={16}>
                                                  {liveData?.data?.lastActivity?.address && (
                                                    <p className="tooltip-description">
                                                      {liveData?.data?.lastActivity?.address}
                                                      &nbsp;(
                                                      <span className="address_age">
                                                        {moment
                                                          .tz(
                                                            `${liveData?.data?.lastActivity?.currentDate} ${liveData?.data?.lastActivity?.currentTime}`,
                                                            'MMDDYY HHmmss',
                                                            tzCode,
                                                          )
                                                          .fromNow()}
                                                      </span>
                                                      )
                                                    </p>
                                                  )}
                                                </Col>
                                              </Row>
                                            </li>
                                            <li>
                                              <Row gutter={20}>
                                                <Col span={8}>
                                                  <p className="p-body">Date:</p>
                                                </Col>
                                                <Col span={16}>
                                                  <p className="tooltip-description">
                                                    <span className="odometer-current-location">
                                                      {liveData?.data?.lastActivity
                                                        ? moment(
                                                            `${liveData?.data?.lastActivity?.currentDate} ${liveData?.data?.lastActivity?.currentTime}`,
                                                            'MMDDYY',
                                                          ).format(DAY_MONTH_YEAR)
                                                        : moment().format(DAY_MONTH_YEAR)}
                                                    </span>
                                                  </p>
                                                </Col>
                                              </Row>
                                            </li>

                                            <li>
                                              <Row gutter={20}>
                                                <Col span={8}>
                                                  <p className="p-body">Odometer:</p>
                                                </Col>
                                                <Col span={16}>
                                                  <p className="tooltip-description">
                                                    <span className="odometer-current-location">
                                                      {liveData?.data?.lastActivity?.odoMeterMillage || 0}
                                                    </span>
                                                  </p>
                                                </Col>
                                              </Row>
                                            </li>
                                          </ul>
                                        </div>
                                      </>
                                    </div>
                                  </InfoWindow>
                                )}
                              </GoogleMap>
                            </>
                          ) : (
                            'is Loading...'
                          )}
                        </div>
                      ) : null}
                    </Col>
                  </Row>
                </ul>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default CurrentLocation;
